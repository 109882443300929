import { render, staticRenderFns } from "./svg_icons.vue?vue&type=template&id=739fb856&"
import script from "./svg_icons.vue?vue&type=script&lang=js&"
export * from "./svg_icons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/xmmedia/foodielicious/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('739fb856')) {
      api.createRecord('739fb856', component.options)
    } else {
      api.reload('739fb856', component.options)
    }
    module.hot.accept("./svg_icons.vue?vue&type=template&id=739fb856&", function () {
      api.rerender('739fb856', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "html/js/src/common/svg_icons.vue"
export default component.exports