import Vue from 'vue';

import svg_icons from './common/svg_icons.vue';

// SASS/CSS
import '../../css/sass/public.scss';

// images
import '../../images/icons-public.svg';

// disable the warning about dev/prod
Vue.config.productionTip = false;

import VueSilentbox from 'vue-silentbox'
Vue.use(VueSilentbox)

new Vue({
    el: '#app',

    components: {
        'svg-icons': svg_icons,
    },

    data: {
        show: false,
        faq1: false,
        faq2: false,
        faq3: false,
        faq4: false,
        faq5: false,
        faq6: false,
        faq7: false,
        faq8: false,
        faq9: false,
        faq10: false,
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.windowResize);
        });
    },
    
    methods: {
        toggle(e) {
            this.show = !this.show
            e.preventDefault();
        },
        faqToggle1(e) {
            this.faq1 = !this.faq1
            e.preventDefault();
        },
        faqToggle2(e) {
            this.faq2 = !this.faq2
            e.preventDefault();
        },
        faqToggle3(e) {
            this.faq3 = !this.faq3
            e.preventDefault();
        },
        faqToggle4(e) {
            this.faq4 = !this.faq4
            e.preventDefault();
        },
        faqToggle5(e) {
            this.faq5 = !this.faq5
            e.preventDefault();
        },
        faqToggle6(e) {
            this.faq6 = !this.faq6
            e.preventDefault();
        },
        faqToggle7(e) {
            this.faq7 = !this.faq7
            e.preventDefault();
        },
        faqToggle8(e) {
            this.faq8 = !this.faq8
            e.preventDefault();
        },
        faqToggle9(e) {
            this.faq9 = !this.faq9
            e.preventDefault();
        },
        faqToggle10(e) {
            this.faq10 = !this.faq10
            e.preventDefault();
        },
        windowResize() {
            this.show = false;
        },
    },
});
